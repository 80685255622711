html {
  box-sizing: border-box;
}
*, *:before, *:after {
  box-sizing: inherit;
}
body {
    margin: 0;
    background-color: #111;
    font: 14px/1.5 'Merriweather', serif;
}
.main {
    display: flex;
    color: #fff;
    flex-direction: column;
}
.left, .right {
    min-height: 100vh;
    padding: 12px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

@media screen and (min-width: 60em) {
    .main {
        flex-direction: row;
    }

    .left, .right {
        width: 50%;
    }
}

.social-proof {
    margin: 32px 0 0;
}
.social-proof__img {
    height: 40px;
    width: 40px;
    border-radius: 100%;
    margin-left: -12px;
    border: 2px solid #fff;
}
.lead {
    margin: 12px 0 32px;
    font-size: 20px;
    display: inline-block;
    max-width: 500px;
    text-align: center;
}
.sign-up-form {
    display: flex;
    justify-content: center;
    margin-bottom: 8px;
    max-width: 100%;
    width: 420px;
}
.sign-up-form__email {
    font: inherit;
    border-radius: 2px;
    border: 0;
    padding: 4px 8px;
    font-size: 18px;
    margin-right: 4px;
    flex: 1 1 auto;
    width: 100%;
}
.sign-up-form__button {
    font: inherit;
    border-radius: 2px;
    font-size: 20px;
    border: 0;
    padding: 4px 8px;
    cursor: pointer;
    background-color: #19A974;
    box-shadow: 0 2px #137752;
}
.disclaimer {
    opacity: 0.8;
    max-width: 500px;
    margin-top: 36px;
    text-align: center;
}
.screen {
}

.link {
  text-decoration: underline;
  color: #357EDD;
  transition: color .15s ease-in;
}

.link:link,
.link:visited {
  transition: color .15s ease-in;
}

.link:hover   {
  transition: color .15s ease-in;
}

.link:active  {
  transition: color .15s ease-in;
}

.link:focus   {
  transition: color .15s ease-in;
  outline: 1px dotted currentColor;
}

.privacy-policy {
    background-color: #333;
    /*color: #222;*/
    max-width: 500px;
    border-radius: 2px;
    padding: 16px;
    /*border: 1px solid #FFD700;*/
}

.privacy-policy__title {
    margin: 0 0 0 24px;
    display: none;
}

.privacy-policy__list {
    padding-left: 24px;
    margin: 0;
}

